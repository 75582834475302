import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { graphql } from "gatsby"
import styled from "@emotion/styled"
import Layout from "components/Layout"
import { RichText } from "prismic-reactjs"

const ContactBlockContainer = styled("div")`
  margin-bottom: 1em;

  & svg {
    width: 75%;

    @media (max-width: 768px) {
      width: 160%;
      left: -60%;
      top: 20px;
      position: relative;
    }
  }

  & h1,
  h2,
  h3,
  h5,
  h6 {
    margin-bottom: 0px;
    text-transform: uppercase;
  }

  .contactDetails p:nth-child(3) {
    color: red;
  }

  .contactDetails h6 {
    margin-top: 70px;
    margin-bottom: 10px;
    font-family: "NewEddy", "Inter", sans-serif;
    font-weight: normal;
    font-size: 32px;
    letter-spacing: 3px;
  }

  .impressumDetails {
    & h6 {
      margin-top: 70px;
      margin-bottom: 10px;
      font-family: "NewEddy", "Inter", sans-serif;
      font-weight: normal;
      font-size: 32px;
      letter-spacing: 3px;
    }

    & a {
      color: black;
      text-decoration: none;

      & :hover {
        text-decoration: underline;
      }
    }

    & p {
      min-height: 1em;
    }
  }

  .credits {
    margin-top: 24px;
    font-size: 16px;

    & a {
      display: inline-block;
      text-decoration: none;
      margin-top: 64px;
      margin-bottom: 24px;
      color: black;

      & :after {
        content: "";
        width: 0px;
        height: 2px;
        display: block;
        background: black;
        transition: 300ms;
      }

      & :hover {
        cursor: pointer;
      }

      & :hover:after {
        width: 100%;
      }

      & visited {
        color: black;
      }
    }
  }
`

const Contact = ({ impressum, meta }) => (
  <>
    <Helmet
      title={`Contact | Impressum`}
      titleTemplate={`%s | ${meta.title}`}
      meta={[
        {
          name: `description`,
          content: meta.description,
        },
        {
          property: `og:title`,
          content: `Contact | Impressum`,
        },
        {
          property: `og:description`,
          content: meta.description,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: meta.author,
        },
        {
          name: `twitter:title`,
          content: meta.title,
        },
        {
          name: `twitter:description`,
          content: meta.description,
        },
      ].concat(meta)}
    />
    <Layout>
      <ContactBlockContainer>
        <div className="contactDetails">
          {RichText.render(impressum.titel)}
          {RichText.render(impressum.address)}
          {RichText.render(impressum.telephone)}
          {RichText.render(impressum.email_address)}
        </div>
        <div className="impressumDetails">
          {RichText.render(impressum.legal)}
        </div>
        <div className="credits">
          © {new Date().getFullYear()} — Mosh
          <br />
          <a href="https://www.vfxjohow.io" target="_blank" rel="noreferrer">
            &#60;&#47; developed by V.F.X. Johow &#62;
          </a>
        </div>
      </ContactBlockContainer>
    </Layout>
  </>
)

export default ({ data }) => {
  const impressumContent = data.prismic.allImpressums.edges.slice(0, 1).pop()
  const meta = data.site.siteMetadata
  if (!impressumContent) return null

  return <Contact impressum={impressumContent.node} meta={meta} />
}

Contact.propTypes = {
  impressum: PropTypes.object.isRequired,
}

export const query = graphql`
  {
    prismic {
      allImpressums {
        edges {
          node {
            address
            email_address
            legal
            telephone
            titel
            _linkType
            _meta {
              uid
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        title
        description
        author
      }
    }
  }
`
